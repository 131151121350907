import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["latitude", "longitude", "skip", "form"];
  declare readonly latitudeTarget: HTMLInputElement;
  declare readonly longitudeTarget: HTMLInputElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly skipTarget: HTMLFormElement;

  getCurrentPosition(event) {
    event.preventDefault();
    document.querySelector("html").ariaBusy = "true";

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.latitudeTarget.value = `${position.coords.latitude}`;
        this.longitudeTarget.value = `${position.coords.longitude}`;
        document.querySelector("html").ariaBusy = "false";
        this.formTarget.submit();
      },
      () => {
        this.skipTarget.value = "true";
        document.querySelector("html").ariaBusy = "false";
        this.formTarget.submit();
      },
    );
  }
}
